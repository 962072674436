import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';


// Keyframes for the circle animation
const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
`;

// Styled components for the UI
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  background-color: #f7f7f7;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const Circle = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #007bff;
  animation: ${pulse} 2s infinite;
  margin-bottom: 20px;
`;

const Timer = styled.div`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
`;

const PhoneCall = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [callStatus, setCallStatus] = useState('idle'); // 'idle', 'calling', 'connected', 'ended'
  const [timer, setTimer] = useState(0);

  const handleCall = () => {
    setCallStatus('calling');


    // Perform the API call
    fetch(`${process.env.REACT_APP_API_URL}/api/initiate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ to: phoneNumber }),
      })
        .then((response) => {
          if (response.ok) {
            // Simulate call connection
            setTimeout(() => {
              setCallStatus('connected');
              startTimer();
            }, 3000);
          } else {
            throw new Error('Call failed');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          setCallStatus('idle'); // Reset status if the call fails
        });
  };

  const startTimer = () => {
    setTimer(0);
    const timerInterval = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timerInterval);
      setCallStatus('ended');
      setTimeout(() => {
        setCallStatus('idle');
      }, 2000); // Simulate the delay before starting a new call
    }, 10000); // Simulate a 10-second call duration
  };


  return (
    <Container>
        {callStatus === 'calling' && <Circle />}
      {callStatus === 'connected' && <Timer>Call Duration: {timer}s</Timer>}
      {callStatus === 'ended' && <Timer>Call Ended</Timer>}
      {(callStatus === 'idle' || callStatus === 'ended') && (
        <>
      <InputContainer>
        <PhoneInput
          country={'us'}
          value={phoneNumber}
          onChange={setPhoneNumber}
          inputStyle={{
            width: '100%',
            fontSize: '1rem',
            borderRadius: '4px',
            border: '1px solid #ccc',
          }}
          enableSearch={true}
        />
      </InputContainer>

      <Button onClick={handleCall}>Call</Button>
        </>
      )}
    </Container>
  );
};

export default PhoneCall;

import React from 'react';
// import VoiceRecorder from './components/VoiceRecorder/VoiceRecorder';
import PhoneCall from './components/PhoneCall/PhoneCall';

function App() {
  return (
    <div className="App">
      {/* <VoiceRecorder /> */}
      <PhoneCall />
    </div>
  );
}

export default App;
